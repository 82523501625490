import React,{useState} from 'react'
import { Button } from '../ButtonElements'
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img } from './InfoElements'
import zigzag from '../../images/zig zag.png'
import corporateImage from "../../images/corporate.png"
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavLinks, NavItem, NavBtn, NavBtnLink, subMenu, subMenuList } from '../Navbar/NavbarElements'



const Corporate = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, alt, img, primary, dark, dark2 }) => {

  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <InfoContainer lightBg={lightBg} id='corporate'>
        <InfoWrapper style={{backgroundColor: '#ffffff'}}>
          <InfoRow imgStart={true}>
            <Column1>
              <TextWrapper>
                <span style={{color: '#000000', fontSize: '42px'}}>
                <span style={{fontWeight: '800',  textAlign: 'right', color: '#263063', flex: 1}}>
                  Moving Your Business Forward 
                  </span>  
                  <br/>

                </span><br/>
                <Subtitle darkText={darkText} style={{textAlign:"left",color:"rgba(0,0,0,.9)",fontSize:'15px'}}>
                Little is a one stop shop that allows corporates to utilize multiple mobility services such as
                :
               
                
                <div style={{display:'flex',justifyContent:'left',alignItems:"center",marginTop:'7px',marginBottom:'8px'}}>

                <ul style={{textAlign:'left',color:"rgba(0,0,0,.8)",marginLeft:"40px"}}>

                <li> Taxi </li>
                <li> Shuttle </li>
                <li> Logistics </li>
                <li> Executive vehicles </li>
                </ul>

                </div>

                <div>
                The Corporate platform allows companies to scale transportation needs across the company. 

                  </div>
                  <div style={{marginTop:'10px'}}>
                  Offering real time visibility that allows companies to minimize the risk associated with the transportation budget and controls

                  {/* improving efficiency by providing 100% audit trail of the transportation cost for the company  */}

                  </div>
                  

                  </Subtitle>



                  <NavBtn style={{ display:'flex' ,justifyContent:"right"}}>

<NavBtnLink onClick={()=> window.open("https://corp.little.africa/form/register", "_blank")}>CORPORATE ENROLLMENT
</NavBtnLink>

</NavBtn>

              </TextWrapper>
            </Column1>
            <Column2 >
            <ImgWrap >
                <Img src={corporateImage}  alt={alt}/>
              </ImgWrap>
            </Column2>
            
          </InfoRow>
        </InfoWrapper>
        
      </InfoContainer>
    </>
  )
}

export default Corporate
