import styled from 'styled-components';
import background from "../../images/Hero.png"

export const InfoSec = styled.div`
    color: #fff;
    padding: 160px 0;
    background: url(${background}) top right no-repeat;
    background-size: 740px 740px;
    // background-color: #000000;

    // background: ${({lightBg}) => (lightBg ? '#fff' : '#fff')};
    @media screen and (max-width: 960px) {
      background-image:none;
    }
`

export const InfoRow = styled.div`
  display: flex;
  // margin: 0 -15px -15px -15px;
  margin-left: 50px;
  flex-wrap: wrap;
  align-items: center;
  // flex-direction: ${({ imgStart }) => (imgStart ? 'row-reverse' : 'row')};
  @media screen and (max-width: 960px) {
    margin-left: 5px;
  }
`;


export const PhoneContainer = styled.div`
    // background-color: #000000;
    max-width: 450px;
    height: 500px;
    display: flex;
    justify-content: center;
`

export const PhoneFrame = styled.div`
// background-color: green;
position: relative;
// width: 980px;
z-index: 1;
// -ms-transform: skewX(-1deg); /* IE 9 */
//   transform: skewX(-1deg);
`

export const SmartPhone = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  min-height: auto;
  
`;

export const SmartPhoneVideo = styled.video`
    background-color: #f1f3f4;
    left: 50%;
    min-height: 98.6%;
    position: absolute;
    top: 52%;
    transform: translate(-50%, -50%);
    width: 95.5%;
    z-index: -1;
    
`;


export const InfoColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
  @media (min-width: 480px) and (max-width: 1200px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const TextWrapper = styled.div`
  max-width: 640px;
  padding-top: 0;
  font-size: 42px;
  padding-bottom: 60px;
  @media (min-width: 480px) and (max-width: 1200px) {
    padding-bottom: 65px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    padding-bottom: 65px;  
  }
  @media screen and (max-width: 960px) {
    font-size: 32px
  }
`;

export const ImgWrapper = styled.div`
   background-color: #000000;
  max-width: 555px;
  display: flex;
  justify-content: ${({ start }) => (start ? 'flex-start' : 'flex-end')};
`;

export const TopLine = styled.div`
  color: ${({ lightTopLine }) => (lightTopLine ? '#a9b3c1' : '#4B59F7')};
  font-size: 18px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 500px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#1c2237')};
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ lightTextDesc }) => (lightTextDesc ? '#a9b3c1' : '#1c2237')};
`;