import React from 'react';
import { Button } from '../../globalStyles';
import { AiFillThunderbolt } from 'react-icons/ai';
import { GiCrystalBars } from 'react-icons/gi';
import { GiCutDiamond, GiRock } from 'react-icons/gi';
import { GiFloatingCrystal } from 'react-icons/gi';
import arrow3 from '../../images/arrow-3.png'
import icon from '../../images/icon.png'
import arrow1 from '../../images/arrow.png'

import Icon1 from '../../images/svg-1.svg'
import Icon2 from '../../images/svg-2.svg'
import Icon3 from '../../images/svg-3.svg'
import arrow2 from '../../images/arrow-2.png'
// import arrow3 from '../../images/arrow-3.png'
import arrow4 from '../../images/arrow-4.png'
import arrow5 from '../../images/arrow-1.png'

import iconDelivery from '../../images/icon-4.png'
import iconEnter from '../../images/icon-2.png'
import iconHealth from '../../images/icon-3.png'
import iconCorporate from '../../images/icon-1.png'



import { IconContext } from 'react-icons/lib';
import {
  PricingSection,
  PricingWrapper,
  PricingHeading,
  PricingContainer,
  PricingCard,
  PricingCardInfo,
  PricingCardIcon,
  PricingCardPlan,
  PricingCardCost,
  PricingCardLength,
  PricingCardFeatures,
  PricingCardFeature,
  PricingColumn
} from './Pricing.elements';
import { ServicesCard, ServicesIcon, ServicesH2, ServicesP, ServicesSection, ServiceSectionTitle } from './ServiceElements';

 const Pricing = () => {
  return (
    <IconContext.Provider value={{ color: '#a9b3c1', size: 64 }}>
      <PricingSection id='services'>
        <PricingWrapper>
          {/* <PricingHeading>Our Services</PricingHeading> */}
          <PricingContainer>

        <PricingColumn >

        <ServicesSection>
            <ServiceSectionTitle style={{fontWeight:'bold'}}>
                Service Portfolio
            </ServiceSectionTitle>
        </ServicesSection>

        <ServicesCard back='#f0f0fe' to='mobility'>
          <ServicesIcon src={icon}/>
          <ServicesH2>Mobility</ServicesH2>
          <ServicesP>Get to places with ease for every occasion. </ServicesP>
          {/* <ServicesIcon src={arrow1} style={{marginTop: '10px'}}/> */}
          <img src={arrow1} width="20" style={{marginTop: '25px'}} alt='icon'/>
        </ServicesCard>

        
           
            </PricingColumn>
            
            <PricingColumn style={{paddingTop: '30px'}}>
              <ServicesCard back='#edf9f9' to='delivery'>
                <ServicesIcon src={iconDelivery}/>
                <ServicesH2>Deliveries</ServicesH2>
                <ServicesP>From groceries to delicacies, from medicines to documents delivered with real-time tracking.  </ServicesP>
                <img src={arrow4} width="20" style={{marginTop: '25px'}} alt='icon'/>
              </ServicesCard>
             
              <ServicesCard back='#fff7eb' to='entertainment'>
                <ServicesIcon src={iconEnter}/>
                  <ServicesH2>Entertainment</ServicesH2>
                  <ServicesP>Forget about queues, snacks and transport. Go to any event stress-free 
                  </ServicesP>
                  <img src={arrow3} width="20" style={{marginTop: '25px'}} alt='icon'/>
                </ServicesCard>
          </PricingColumn>

            <PricingColumn >

            {/* <ServicesCard back='#feeee9' to='health'>
                <ServicesIcon src={iconHealth}/>
                <ServicesH2>Healthcare</ServicesH2>
                <ServicesP>Book clinic visits or Get your tests done at the comfort of your home in an affordable way. </ServicesP>
                <img src={arrow2} width="20" style={{marginTop: '25px'}} alt='icon'/>
            </ServicesCard> */}

          <ServicesCard back='#ebf8f1' to='corporate'>
            <ServicesIcon src={iconCorporate}/>
            <ServicesH2>Corporate</ServicesH2>
            <ServicesP>Transport, deliveries, food, airtime, attendance management, bill payments all covered under one solution.</ServicesP>
            <img src={arrow1} width="20" style={{marginTop: '25px'}} alt='icon'/>
          </ServicesCard>
          
            </PricingColumn>
          </PricingContainer>
        </PricingWrapper>
      </PricingSection>
    </IconContext.Provider>
  );
}

export default Pricing