import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import { NavLogo } from '../components/Navbar/NavbarElements'
import Sidebar from '../components/SideBar'
import { Container, FormWrap, Icon, FormContent, Form, FormInput, FormH1, FormLabel, FormButton, Text, ContactLogo } from './SigninElements'
import logo from '../../src/images/little-logo.png'

const SignIn = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () =>{
    setIsOpen(!isOpen);
  }

  return (
    <>
      <Container>
      {/* <Sidebar isOpen={isOpen} toggle={toggle} />
          <Navbar toggle={toggle}/> */}
          
          <Form>
          <center><img src={logo}/></center><br/><br/>

          <div style={{fontWeight: '700' , fontSize: '20px',fontFamily: 'Arial, Helvetica, sans-serif'}}>LittleOne Infinity Private Limited</div>
          <br/><div style={{fontFamily: 'Arial, Helvetica, sans-serif'}}>Address: 5th Floor 524, Indraprasth Business Park, Besides D A V School, Makarba, Ahmedabad 380051 Gujarat, India</div>
          <br/><div style={{fontFamily: 'Arial, Helvetica, sans-serif'}}> Phone:  <b>+917016443239</b></div>
          <br/><div style={{fontFamily: 'Arial, Helvetica, sans-serif'}}>CIN No: U72200GJ2022PTC130326</div>
          

          </Form>
          
      </Container>
    </>
  )
}

export default SignIn
