import React from "react";
import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId }) => (
  <div className="video-responsive">
   <iframe src="https://www.voanews.com/embed/player/0/6322914.html?type=video" frameborder="0" scrolling="no" width="400" height="251" allowfullscreen></iframe>
  </div>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;