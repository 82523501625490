import React, {useEffect, useState} from 'react'
import Footer from '../components/Footer';
import Hero from '../components/Hero'
import Info from '../components/Info'
import { homeObjOne, homeObjTwo, homeObjThree} from '../components/Info/Data';
import Navbar from '../components/Navbar'
import Services from '../components/Services';
import Sidebar from '../components/SideBar';
import Mobility from '../components/Mobility';
import Ride from './../components/Ride';
import Deliveries from './../components/Deliveries';
import Corporate from './../components/Corporate';
import Entertainment from '../components/Entertainment';
import Blogs from '../components/Blogs';
import Offers from '../components/Offers';
import Subscribe from '../components/Subscribe';
import GlobalFonts from '../fonts/fonts';
import TopSlider from './../components/Hero/InfoSection';
import { homeObjFour, sliderData } from './Data';
import Pricing from './../components/Services/Pricing';
import InfoSection from './../components/InfoSection/InfoSection';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import axios from 'axios';



const Home = () => {
 

  const [isOpen, setIsOpen] = useState(false)

  const [state, setState] = useState({
    countryName: "",
    countryCode: "",
    city: ""
  });

  // useEffect(() => {
  //   getGeoInfo();
  // }, []);

  const getGeoInfo = () => {
    axios
      .get("https://extreme-ip-lookup.com/json/?key=pLcITUlM7G7MO8LRJ5tg")
      .then((response) => {
        let data = response.data;

        // console.log("my data is ------>" , data);
        // alert(JSON.stringify(Intl.DateTimeFormat().resolvedOptions().timeZone))
        setState({
          ...state,
          countryName: data.country_name,
          countryCode: data.country_calling_code,
          city: data.city
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  const toggle = () =>{
    setIsOpen(!isOpen);
  }

  return (
    <div style={{margin: 0, padding: 0, maxWidth: '100%', overflowX: 'clip'}}>
      <BrowserView>
      <GlobalFonts />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle}/>
      <TopSlider  {...sliderData}/>
      {/* <TopSlider  {...sliderData}/> */}
      {/* <Hero /> */}
      <Pricing/>
      {/* <Services /> */}
      {/* <InfoSection {...homeObjFour} /> */}
      <Mobility {...homeObjTwo}/>
      <Ride {...homeObjOne}/>
      <Deliveries {...homeObjOne}/>
      <Corporate {...homeObjOne}/>

      <Entertainment {...homeObjOne}/>

      <Blogs {...homeObjOne}/>
      <Offers {...homeObjOne}/>
      <Subscribe {...homeObjOne}/>

      {/* <Info {...homeObjTwo}/>
      <Info {...homeObjTwo}/>
      <Services />
      <Info {...homeObjThree}/> */}

      <Footer />
        </BrowserView>
        <MobileView>
        <GlobalFonts />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle}/>
      {/* <TopSlider  {...sliderData}/> */}
      <TopSlider  {...sliderData}/>
      {/* <TopSlider  {...sliderData}/> */}
      {/* <Hero /> */}
      <Pricing/>
      {/* <Services /> */}
      {/* <InfoSection {...homeObjFour} /> */}
      <Mobility {...homeObjTwo}/>
      <Ride {...homeObjOne}/>
      <Deliveries {...homeObjOne}/>
      <Corporate {...homeObjOne}/>

      <Entertainment {...homeObjOne}/>

      <Blogs {...homeObjOne}/>
      <Offers {...homeObjOne}/>
      <Subscribe {...homeObjOne}/>

      {/* <Info {...homeObjTwo}/>
      <Info {...homeObjTwo}/>
      <Services />
      <Info {...homeObjThree}/> */}

      <Footer />
        </MobileView>
     
    </div>
  )
}

export default Home
