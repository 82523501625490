import styled from 'styled-components';
import { MdArrowForward, MdKeyboardArrowRight } from 'react-icons/md'
import background from "../../images/Hero.png"
import { injectGlobal } from 'styled-components';
// import mainFont from '../../fonts/Proxima Nova Semibold.otf';



export const HeroContainer = styled.div`
  background: url(${background}) top right no-repeat;
  background-size: contain !important;
  background-color: #edf9f9;
background-repeat: no-repeat;
  color: #000000;
  display: flex;
  // justify-content: center;
  align-items: center;
  // padding: 0 30px;
  height: 800px;
  position: relative;
  margin-left: auto;
  z-index: 1;
  :before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0,0,0,0.2)0%, rgba(0,0,0,0.6)100%),  
    linear-gradient(180deg, rgba(0,0,0,0.2)0%, transparent 100%)
    z-index: 2;
  }
`
export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  
`

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`

export const HeroContent = styled.div`
  
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  background: red;
  height: 400px;
  // margin-top: 150px;
  margin-left: 150px;
  font-family: 'Proxima Nova';
`

export const HeroH1 = styled.h1`
  color: #fff;
  font-size: 48px;
  text-align: center;
  @media screen and (max-width: 768px) {
    font-size: 40px
  }
  @media screen and (max-width: 480px) {
    font-size: 32px
  }
`

export const HeroP = styled.p`
  margin-top: 24px;
  color: #000000;
  font-size: 24px;
  text-align: center;
  max-width: 600px;

  @media screen and (max-wodth: 768px) {
    font-size: 14px
  }
  @media screen and (max-wodth: 480px) {
    font-size: 12x
  }
`

export const HeroBtnWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  width: 200px;
  align-items: left;
`

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`
export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`



// responsive design components



