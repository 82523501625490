import React, {useState, useEffect} from 'react'
import { FaBars } from 'react-icons/fa'
import {IconContext} from 'react-icons/lib'
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavLinks, NavItem, NavBtn, NavBtnLink, subMenu, subMenuList, NavBtnLinkSacco } from './NavbarElements'
import { animateScroll as scroll } from 'react-scroll';

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false)
  const changeNav = () => {
    if(window.scrollY >= 80) {
      setScrollNav(true)
    } else {
      setScrollNav(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, [])

  const toggleHome = () => {
    scroll.scrollToTop();
  }

  return (
    <>
    <IconContext.Provider value={{ color: '#fff'}}>
      <Nav scrollNav={scrollNav}>
      
        <NavbarContainer>
        <MobileIcon onClick={toggle}>
            <FaBars style={{color: '#3d4143'}}/>
          </MobileIcon>
          <NavLogo to='/' onClick={toggleHome}></NavLogo>
          
          <NavMenu>
          <NavItem>
              <NavLinks to='home'
              smooth={true} duration={500} spy={true} exact='true' offset={-80}
              >Home</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='services'
              smooth={true} duration={500} spy={true} exact='true' offset={-80}
              >Services</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='offers'
              smooth={true} duration={500} spy={true} exact='true' offset={-80}
              >Offers</NavLinks>
            </NavItem>




            <NavItem sx={{border:"1px solid red"}}>





              {/* <NavLinks to=''
              smooth={true} duration={500} spy={true} exact='true' offset={-80}
              > */}
                {/* <subMenu>
                  Register
                  <div style={{marginTop: 10}}>
                  <p>Register as a driver</p>
                  <p>Register a Partner</p>
                  <p></p>
                  </div>
                </subMenu> */}
                <div className="dropdown" style={{marginTop:"10px"}} >
                  <button className="dropbtn">Register</button>
                  <div className="dropdown-content">



                    <a href="https://drivers.little.bz/login" target={'_blank'} onClick={() => window.open(
                        'https://drivers.little.bz/login',
                        '_blank' // <- This is what makes it open in a new window.
                      )}>Register as a Driver
                      
                      </a>


                  





                    <a href="https://partners.little.bz/login" target={'_blank'} onClick={() => window.open(
                        'https://partners.little.bz/login',
                        '_blank' // <- This is what makes it open in a new window.
                      )}>Register as a Partner
                      </a>
                  </div>
                </div>


              {/* </NavLinks> */}


            </NavItem>
                
            {/* <NavItem>
              <NavLinks to='signup'
              smooth={true} duration={500} spy={true} exact='true' offset={-80}
              >Sign Up</NavLinks>
            </NavItem> */}
          </NavMenu>
          <NavBtn>
         


              <NavBtnLink onClick={()=> window.open("https://corp.little.africa/form/register", "_blank")}>CORPORATE ENROLLMENT</NavBtnLink>


          </NavBtn>

          
        </NavbarContainer>
        <NavBtn>
            <NavBtnLinkSacco onClick={()=> window.open("https://littlesacco.craftsilicon.com/ib", "_blank")}>JOIN SACCO</NavBtnLinkSacco>
          </NavBtn>
      </Nav>
    </IconContext.Provider>
    </>
  )
}

export default Navbar
