import React from 'react'
import { Button } from '../ButtonElements'
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img } from './InfoElements'
import rideImage from '../../images/Ride1.png'
import zigzag from '../../images/zig zag.png'
import background from "../../images/EntBack.png"
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavLinks, NavItem, NavBtn, NavBtnLink, subMenu, subMenuList } from '../Navbar/NavbarElements'


const Entertainment = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, alt, img, primary, dark, dark2 }) => {
  return (
    <>
      <InfoContainer lightBg={false} id='entertainment' style={{marginTop:'20px'}}>
        <InfoWrapper>
          <InfoRow imgStart={false}>
            <Column1>
              <TextWrapper>
              {/* <TopLine><img alt="little" src={zigzag}/></TopLine> */}
                <Heading lightText={false}>Enter Entertainment, the way it should be
                <TopLine><img alt="little" src={zigzag}/></TopLine>
                
                 </Heading>
                
                <Subtitle darkText={true}> 
                from getting to the theatre to celebrating the movie as a buff, everything is offered under your Little app! 
            
                </Subtitle>
              </TextWrapper>
            </Column1>
            <NavBtn style={{ display:'flex' ,justifyContent:"left",position:'relative',top:'-50px'}}>
<NavBtnLink onClick={()=> window.open("https://apps.little.africa/movies", "_blank")}>
  
BOOK MOVIE TICKET
</NavBtnLink>
</NavBtn>

            
            <Column2>
              <ImgWrap>
                <Img src={background} alt={alt}/>
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default Entertainment
