import styled , { keyframes } from 'styled-components'
import shape1 from '../../images/shape1.png'
import shape2 from '../../images/shape2.png'
import shape3 from '../../images/shape3.png'
import shape4 from '../../images/shape4.png'
import zigzag from '../../images/zig zag.png'
import { Link as LinkS } from 'react-scroll'



const breatheAnimation = keyframes`
from {
  background-position: 0 0;
}
to {
  background-position: 100% 0;
}
`

export const ServicesContainer = styled.div`
  height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  background-image: url(${shape1}), url(${shape2});
  background-position: left top, right center;
  background-repeat: no-repeat, no-repeat;
  background-size: 100px;
  // animation: ${breatheAnimation} 10s linear infinite alternate;

  @media screen and (max-width: 768px) {
    height: 1100px;
  }

  @media screen and (max-width: 480px) {
    height: 1300px;
  }
 `

 export const ServicesInnerContainer = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: transparent;

  background-image: url(${shape3}), url(${shape4});
  background-position: center bottom,  top 0px right 280px;
  background-repeat: no-repeat, no-repeat;
  background-size: 180px;

  @media screen and (max-width: 768px) {
    height: 1100px;
  }

  @media screen and (max-width: 480px) {
    // height: auto;
    flex-direction: column;
  }
 `

 export const ServicesSection = styled.div`
  // height:100%;
  width: 270px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // background: GRAY;
  margin-left: 20px;
  // @media screen and (max-width: 768px) {
  //   height: 1100px;
  // }

  // @media screen and (max-width: 480px) {
  //   height: 1300px;
  // }
 `

 export const ServiceSectionTitle = styled.div`
  // font-size: 2.5rem;
  height: 100px;
  width: 50%;
  color: #000000;
  // margin-bottom: 30px;
  margin-top: 20px;
  font-size: 28px;
  background: transparent;
  line-height: 1.5;
  background-image: url(${zigzag});
  background-position: left bottom;
  background-repeat: no-repeat;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }  
`
export const ServicesWrapper = styled.div`
display: flex;
justify-content: center;

background-color:green;
align-items: center;
@media screen and (max-width: 960px) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
`
export const ServicesCard = styled(LinkS)`
  background: ${props => props.back};
  display: flex;
  min-height: 270px;
  width:250px;
  flex-direction: column;
  padding: 20px;
  // justify-content: flex-start;
  // align-items: center;
  border-radius: 5px;
  max-height: 340px;
  padding-bottom:  30px;
  padding-left: 15px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;
  margin-bottom: 10px;
  margin-top: 10px;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor:pointer;
  }

  @media screen and (max-width: 960px) {
    width: 90%;
    &:hover {
      transform: none;
    }
  }
`
export const ServicesIcon = styled.img`
  height: 70px;
  width: 70px;
  margin-bottom: 20px;

`
export const ServicesH1 = styled.h1`
  font-size: 27px;
  color: #fff;
  margin-bottom: 64px;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }  
`


export const ServicesH2 = styled.h4`
  font-size: 17px;
  margin-bottom: 30px;
`

export const ServicesP = styled.p`
  font-size: 15px;
  text-align: left;
  line-height: 1.7
`
