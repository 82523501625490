import React from 'react'
import { Button } from '../ButtonElements'
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img } from './InfoElements'
import rideImage from '../../images/Ride1.png'
import background from "../../images/Ride1.png"

const Ride = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, alt, img, primary, dark, dark2 }) => {
  return (
    <>
      <InfoContainer lightBg={false} id='rise'>
        <InfoWrapper>
          <InfoRow imgStart={false}>
            <Column1>
              <TextWrapper>
                
                <Heading lightText={false}>Get to places with ease for every occasion. </Heading>
                <TopLine>AFFORDABLE & COMFORTABLE MOBILITY </TopLine>
                <Subtitle darkText={true}>Little provides on-demand ride request services with an array of car categories to choose from ranging from Economy to Comfort+ .Some of the unique features of Little ride are No price surge and preferred driver option. </Subtitle>
                <BtnWrap>
                  <Button to='home'
                  smooth={true}
                  duration={500}
                  onClick={()=> window.open("https://little.bz/app/main/", "_blank")}
                  spy={true}
                  exact='true'
                  offset={-80}
                  primary={primary ? 1 : 0}
                  dark={dark ? 1 : 0}
                  dark2={dark2 ? 1 : 0}
                  >
                    Get the App
                  </Button>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={rideImage} alt={alt}/>
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default Ride
