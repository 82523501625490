import React from 'react'
import {InfoSec, InfoRow, InfoColumn, TextWrapper, TopLine, Heading, Subtitle, PhoneContainer, Img, PhoneFrame, SmartPhone, SmartPhoneVideo} from './InfoSection.elements'
import { Container, Button } from '../../globalStyles'
import smartphone from '../../images/phone.webp'
import myVideo from '../../images/little_small.mp4'
import myVideoweb from '../../images/little_small.webp'
import myVideogif from '../../images/little_small.gif'


// import { Link } from 'react-router-dom'

 const TopSlider = ({ 
    primary,
    lightBg,
    topLine,
    lightTopLine,
    lightText,
    lightTextDesc,
    headline,
    description,
    buttonLabel,
    img,
    alt,
    imgStart,
    start
}) => {
    return (
        <>
            <InfoSec lightBg={lightBg} id='home'>
                <Container>
                    <InfoRow imgStart={false}>
                        <InfoColumn>
                            <TextWrapper>
                            {/* <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine> */}
                            {/* <Heading lightText={false}>{headline}</Heading>
                            <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle> */}
                            <span style={{color: '#353232', width: '50%', lineHeight:  '1.3',  fontWeight: '700'}}>
                            <span style={{color: '#EDB21F'}}>your</span><br/>
                            <span style={{color: '#00ba75'}}>every</span>
                            <span style={{color: '#008ad0'}}>day</span>.
                            <span style={{color: '#FF0000'}}>every</span>
                            <span style={{color: '#FF0000'}}>thing</span><br/>
                            app!<br/><br/>
                            </span>

                            <span style={{width: '100%' , color: '#595959', fontSize: '20px', fontWeight: '500'}}>Designed to address more and more of your daily lifestyle needs. Commute, eat, deliver and pay with one App.</span><br/><br/>

                            {/* <Link to='/sign-up'> */}
                            <Button big fontBig primary={primary} onClick={()=> window.open("https://little.bz/app/main/", "_blank")}>
                                GET THE APP
                            </Button>
                            {/* </Link> */}
                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn>
                        <PhoneContainer>

                            {/* <Img src={img} alt={alt} /> */}
                            {/* <img src="https://images.unsplash.com/photo-1505156868547-9b49f4df4e04?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;w=1000&amp;q=80" height="500px" style={{zIndex : "-9999"}}/>
                             <iframe style={{position: 'absolute',zIndex: '9999'}} width="140px" height="250px" src="https://www.youtube.com/embed/uYg4cUyJ7v0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                            {/* phone frame */}
                            <PhoneFrame>
                                <SmartPhone src={smartphone}>

                                </SmartPhone>

                                <SmartPhoneVideo width='200px' height='200px' autoPlay muted loop>
                                <source src={myVideo}/>
                                <source src={myVideoweb} onerror="fallback(parentNode)"/>
                                <img src={myVideogif}></img>

                                </SmartPhoneVideo>
                            </PhoneFrame>
                        </PhoneContainer>
                        </InfoColumn>
                    </InfoRow>
                </Container>
            </InfoSec>
        </>
    )
}

export default TopSlider;