import { Link } from 'react-router-dom';
import styled from 'styled-components';

import shape1 from '../../images/shape1.png'
import shape2 from '../../images/shape2.png'
import shape3 from '../../images/shape3.png'
import shape4 from '../../images/shape4.png'
import zigzag from '../../images/zig zag.png'


export const PricingSection = styled.div`
  padding: 100px 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  background-image: url(${shape1}), url(${shape2});
  background-position: left top, right center;
  background-repeat: no-repeat, no-repeat;
  background-size: 100px;
  @media screen and (max-width: 960px) {
    background-image:none
  }
`;

export const PricingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px auto;
  @media screen and (max-width: 960px) {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const PricingHeading = styled.h1`
  color: #000;
  font-size: 48px;
  margin-bottom: 24px;
`;

export const PricingContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  // background-color:green;
  background-image: url(${shape3}), url(${shape4});
  background-position: center bottom,  top 0px right 180px;
  background-repeat: no-repeat, no-repeat;
  // background-size: 180px;
  // align-items: center;
  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-image:none
  }
`;

export const PricingColumn = styled.div`
  display: flex;
  // justify-content: center;
  flex-direction: column;
  // background-color:blue;
  align-items: center;
  width: 100%;
  padding-left: 20px;
  // padding-right: 20px;
  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  @media screen and (max-width: 600px) {
    padding-top: 0;
  }
`;


export const PricingCard = styled(Link)`
  background: #242424;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
  width: 280px;
  height: 500px;
  text-decoration: none;
  border-radius: 4px;
  &:nth-child(2) {
    margin: 24px;
  }
  &:hover {
    transform: scale(1.06);
    transition: all 0.3s ease-out;
    color: #1c2237;
  }
  @media screen and (max-width: 960px) {
    width: 90%;
    &:hover {
      transform: none;
    }
  }
`;

export const PricingCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
  padding: 24px;
  align-items: center;
  color: #fff;
`;

export const PricingCardIcon = styled.div`
  margin: 24px 0;
`;

export const PricingCardPlan = styled.h3`
  margin-bottom: 5px;
  font-size: 24px;
`;

export const PricingCardCost = styled.h4`
  font-size: 40px;
`;

export const PricingCardLength = styled.p`
  font-size: 14px;
  margin-bottom: 24px;
`;

export const PricingCardFeatures = styled.ul`
  margin: 16px 0 32px;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a9b3c1;
`;

export const PricingCardFeature = styled.li`
  margin-bottom: 10px;
`;