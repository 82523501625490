import React from 'react'
import { Button } from '../ButtonElements'
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img } from './InfoElements'
import rideImage from '../../images/Ride1.png'
import zigzag from '../../images/zig zag.png'
import axios from 'axios'

const Subscribe = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, alt, img, primary, dark, dark2 }) => {
  
  const [message, setMessage] = React.useState('');
  const [error, setError] = React.useState(false);
  const [value, handleChange] = React.useState('');

  const handleClick = () => {
    // event.preventDefault();
    setError(false)
    setMessage("loading ...")
    const user = {
      email: value
    };

    // console.log("my getting the following data " , value);
    // alert(value)

    axios.post(`https://www.little.bz/blog/wp-json/wp/v2/users/register`, { email : value })
      .then(res => {
        setError(false)
        console.log(res);
        console.log("my getting the following data " , res.data);
        setMessage("Thank you for subscribing")

      }).catch(error => {
        setError(true)
        console.log("my error is ", error.response.data.message );
        setMessage(error.response.data.message)
    });
  }

  return (
    <>
      <InfoContainer lightBg={false} id='rise' >
        <InfoWrapper>
          <InfoRow imgStart={false} sx={{border:'1px solid red'}}>
            {/* <Column1> */}
           
            <TextWrapper>
             
                <Heading lightText={false} style={{fontSize:'30px'}}>Be the first to know about us more! </Heading>
                
                <Subtitle darkText={false}> 
                Subscribe to our emailing list 
                </Subtitle>
               
              </TextWrapper>
                <div>
                <span style={{color: error ? 'red' : 'green',marginLeft: '20px'}}>{message}</span>

                <div class="containernews" style={{width: '400px',border:'1px solid blue;'}}>
                    <input type="text" class="text_input"placeholder="Your email address" onChange={(e) => handleChange(e.target.value)}/>
                    <button value="Save" class="btn" onClick={() => handleClick()}>SUBSCRIBE</button>
                </div>

                </div>
             
            {/* </Column1> */}
               
            {/* <Column2> */}
           
            {/* </Column2> */}

          {/* <img alt="little" src={zigzag}/> */}
              
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default Subscribe
