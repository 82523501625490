import styled from 'styled-components';
import background from "../../images/Deliveries1.png"
import background2 from "../../images/Deliveries2.png"


export const InfoContainer = styled.div`
  color: #000000;
  background: url(${background})  right no-repeat;
  background-image: url(${background}), url(${background2});
  background-position: right center, left center;
  background-repeat: no-repeat, no-repeat;
  background-size: 100px;
  background-size: contain !important;
  background-color: #edf9f9;
background-repeat: no-repeat; 
  @media screen and  (max-width: 768px) {
    padding: 100px 0;
  }
  @media screen and (max-width: 960px) {
    background-image:none
  }
`
export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  min-height: 500px;
  width: 100%auto;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
  padding-top: 30px;
`
export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)}
  }
`
export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`
export const Column2 = styled.div`
  margin-bottom: 15px;
  // padding: 0 15px;
  grid-area: col2;
`
export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
`
export const TopLine = styled.p`
  color: #8f8f8f;
  font-size: 15px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 38px;
  width: 80%;
  line-height: 1.3;
  font-weight: 600px;
  color: #252f62;

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`
export const Subtitle  = styled.p`
  // max-width: 440px;
  margin-bottom: 15px;
  font-size: 15px;
  line-height: 22px;
  color: '#8f8f8f';
`
export const BtnWrap = styled.div`
  display:flex;
  justify-content: flex-start;
`;
export const ImgWrap = styled.div`
  width: 100%;
  height: 100%;
`;
export const Img = styled.img`
  width: 100%;
  // margin: 0 0 10px 0;
  // padding-right: 0;
  display: none;
  @media screen and (max-width: 768px) {
    display: block;  
  }
`;
