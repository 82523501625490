import styled from 'styled-components';
import background from "../../images/Blog.png"
import background2 from "../../images/Blog2.png"

import background3 from "../../images/BlogBack.png"

export const InfoContainer = styled.div`
  color: #000000;
  // background: url(${background})  right no-repeat;
  // background-image: url(${background}), url(${background2});
  background-position: right center, left center;
  background-repeat: no-repeat, no-repeat;
  background-size: 100px;
  background-size: contain !important;
  background-color: #ffffff;
background-repeat: no-repeat; 
  @media screen and  (max-width: 768px) {
    padding: 100px 0;
  }
`
export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  min-height: 600px;
  width: 100%auto;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
  padding-top: 30px;
`
export const InfoRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 890px;
  align-items: center;
  // background-image: url(${background3});
  margin-bottom: 30px;
  background-position: center center;
  background-repeat: no-repeat, no-repeat;
  // background-color: gray;
  @media screen and (max-width: 768px) {
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)}
  }
`
export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`
export const Column2 = styled.div`
  margin-bottom: 15px;
  // padding: 0 15px;
  grid-area: col2;
`
export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 25px;
  // padding-bottom: 60px;
`
export const TopLine = styled.p`
  color: #8f8f8f;
  font-size: 15px;
  line-height: 16px;
  // font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`

export const Heading = styled.div`
  margin-bottom: 5px;
  font-size: 18px;
  // width: 80%;
  // line-height: 1.3;
  font-weight: 600;
  text-align : center;
  color: #333030;

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`
export const Subtitle  = styled.p`
  max-width: 340px;
  margin-bottom: 15px;
  font-size: 15px;
  line-height: 22px;
  color: '#8f8f8f';
`
export const BtnWrap = styled.div`
  display:flex;
  justify-content: flex-start;
`;
export const ImgWrap = styled.div`
  width: 100%;
  height: 100%;
`;
export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;

export const Offerss = styled.div`
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: row;
  margin: 0 0 10px 0;
  padding-right: 0;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  @media screen and (max-width: 960px) {
   flex-direction: column
  }
`;
