import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SidebarRoute, SideBtnWrap } from './SidebarElements'

const Sidebar = ({isOpen,toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>

          <SidebarLink to='home' onClick={toggle}>Home</SidebarLink>
          <SidebarLink to='services' onClick={toggle}>Services</SidebarLink>
          <SidebarLink to='offers' onClick={toggle}>Offers</SidebarLink>
          <SidebarLink to='offers'onClick={() => window.open(
                        'https://drivers.little.bz/login',
                        '_blank' // <- This is what makes it open in a new window.
                      )}>Register as a Driver</SidebarLink>
          <SidebarLink to='offers' onClick={() => window.open(
                        'https://partners.little.bz/login',
                        '_blank' // <- This is what makes it open in a new window.
                      )}>Register as a Partner</SidebarLink>

          <SidebarLink to='offers' onClick={() => window.open(
                        'https://littlesacco.craftsilicon.com/ib/',
                        '_blank' // <- This is what makes it open in a new window.
                      )}>Join Sacco</SidebarLink>

<SidebarLink to='corporate_enrollment' onClick={()=> window.open('https://corp.little.africa/form/register','_blank')}>Corporate Enrollment</SidebarLink>

          {/* <SidebarLink to='signup' onClick={toggle}>Sign up</SidebarLink> */}

        </SidebarMenu>
        {/* <SideBtnWrap>
          <SidebarRoute to='/signin'></SidebarRoute>
        </SideBtnWrap> */}
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
