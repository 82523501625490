import { createGlobalStyle } from 'styled-components';

import ProximaNovaRegular from './ProximaNova-Regular.woff';
import ProximaNovaRegular2 from './ProximaNova-Regular.woff2';

export default createGlobalStyle`
    @font-face {
        font-family: 'Proxima Nova Rg';
        src: local('Proxima Nova Th'), local('Proxima Nova Th'),
        url(${ProximaNovaRegular2}) format('woff2'),
        url(${ProximaNovaRegular}) format('woff');
        font-weight: 100;
        font-style: normal;
    }
`;