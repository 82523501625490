import React from 'react'
import { Button } from '../ButtonElements'
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img } from './InfoElements'
import rideImage from '../../images/Ride1.png'
import stars from '../../images/stars.PNG'
import background from "../../images/Deliveries1.png"


const Deliveries = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, alt, img, primary, dark, dark2 }) => {
  return (
    <>
      <InfoContainer lightBg={false} id='delivery'>
        <InfoWrapper>
          <InfoRow imgStart={false}>
            <Column1>
              <TextWrapper>
                
                <Heading lightText={false}>5 Star Deliveries all the time!</Heading>
                <TopLine><img alt="little" src={stars}/></TopLine>
                <Subtitle darkText={true}> We offer on-demand delivery services for corporate institutions and individuals 7 days a week, across Nairobi County. 
                </Subtitle>

                <Subtitle darkText={true}> Our deliveries are handled with greater care and speed as we understand each delivery is essential to you. 
 
                </Subtitle>

                <Subtitle darkText={true}>Some of the product categories we serve are: food, drinks, groceries, gas and so much more. 
 
                </Subtitle>
                <span>
                  
                </span>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={background} alt={alt}/>
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default Deliveries
