import React,{useState} from 'react'
import { Button } from '../ButtonElements'
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img } from './InfoElements'
import zigzag from '../../images/zig zag.png'
import ReactPlayer from 'react-player/youtube'
import ModalVideo from 'react-modal-video'
import YoutubeEmbed from "./youtube";


const Info = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, alt, img, primary, dark, dark2 }) => {

  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <InfoContainer lightBg={lightBg} id='mobility'>
        <InfoWrapper style={{backgroundColor: '#f0f0fe'}}>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <span style={{color: '#000000'}}>
                <span style={{fontWeight: '700'}}>Little eMobility</span> - initiative <br/>
                to support the need of the hour! 
                </span><br/>
               <img alt="little" src={zigzag} style={{marginTop: '16px', marginBottom: '16px'}}/>
                <Subtitle darkText={darkText}>
                A Kenyan ride-hailing company has introduced electric bicycle rentals for the first time in the capital, where air pollution and motor vehicle traffic are problems.
                  </Subtitle>

              </TextWrapper>
            </Column1>
            <Column2>
            {/* <div style={{width:"500px", height: "300px", backgroundColor: '#f0f0f0'}}> */}
                <YoutubeEmbed embedId="5UmZQMlf9Tw" />           
                 {/* </div> */}
            {/* <ReactPlayer width='600px' height= "400px" url='https://www.youtube.com/watch?v=5UmZQMlf9Tw' /> */}
            {/* <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="5UmZQMlf9Tw" onClose={() => setOpen(false)} />

            <button className="btn-primary" onClick={()=> setOpen(true)}>VIEW video</button> */}

            
            </Column2>
          </InfoRow>
        </InfoWrapper>
        
      </InfoContainer>
    </>
  )
}

export default Info
